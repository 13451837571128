import {useCallback, useEffect, useMemo} from "react";
import useSubscription from "./useSubscription";
import {triggerEvent} from "@/utils/custom-events";
import useService from "./useService";
import {fetchAddItem} from "@/services/cart";

const useProductForm = ({
  product,
  defaultVariantId,
  defaultSellingPlanId,
  formId,
}) => {
  const id = defaultVariantId ?? product?.variants[0]?.id;
  const quantity = 1;
  const {
    sellingPlans,
    hasSubscription,
    isActive,
    getSellingPlan,
    sellingPlanId,
    setSellingPlanId,
    setSubscriptionActive,
  } = useSubscription(product, defaultSellingPlanId);

  useEffect(() => {
    triggerEvent(`product-form:change:${id}-${formId}`, {
      variantId: id,
      quantity,
      selling_plan: sellingPlanId,
    });
  }, [id, quantity, sellingPlanId, formId]);

  const addToCartFn = useCallback(async () => {
    await fetchAddItem({id, quantity, selling_plan: sellingPlanId});
    triggerEvent("cart:modal:toggle", {
      open: true,
    });
  }, [id, quantity, sellingPlanId]);

  const {loading, serviceCall: addToCart} = useService(addToCartFn);

  const getPrice = useCallback(
    (id, quantity, sellingPlanId) =>
      product.prices.find(
        priceData =>
          priceData.id === id &&
          priceData.quantity === quantity &&
          priceData.selling_plan === sellingPlanId,
      ) ??
      product.prices.find(
        priceData =>
          priceData.id === id && priceData.selling_plan === sellingPlanId,
      ) ??
      product.prices.find(
        priceData => priceData.id === id && priceData.quantity === quantity,
      ) ??
      product.prices.find(priceData => priceData.id === id) ??
      product.prices[0],
    [product],
  );

  const currentPrice = useMemo(
    () => getPrice(id, quantity, sellingPlanId),
    [getPrice, id, quantity, sellingPlanId],
  );

  return {
    id,
    quantity,
    getSellingPlan,
    sellingPlanId,
    sellingPlans,
    hasSubscription,
    isActive,
    setSellingPlanId,
    setSubscriptionActive,
    loading,
    addToCart,
    currentPrice,
    getPrice,
  };
};

export default useProductForm;
